<template>
  <div id="FestivalShowsCashless">
    <qs-kpi-grid
      style="grid-column: 1 / span 4"
      :qlik-ids="['RRDcG', 'rgvvG', 'sNPmMv', 'Pc', 'dvZpn', 'QDcJPEN']"
      :columns="3"
    />

    <QsCard :qlik-ids="['HHVW']" style="grid-column: 1 / span 2" />
    <QsCard :qlik-ids="['uFACkvK']" style="grid-column: 3 / span 2" />

    <QsCard :qlik-ids="['jNRDv']" style="grid-column: 1 / span 4" />

    <QsCard :qlik-ids="['uRjP']" style="grid-column: 1 / span 1" />
    <QsCard :qlik-ids="['dfGmprT']" style="grid-column: 2 / span 3" />

    <QsCard :qlik-ids="['kNvTJL']" style="grid-column: 1 / span 2" />
    <QsCard :qlik-ids="['jAgNaaS']" style="grid-column: 3 / span 2" />
  </div>
</template>

<script>
import QsKpiGrid from "@/components/Qlik/QsKpiGrid";
import QsCard from "@/components/Qlik/QsCard";

export default { components: { QsKpiGrid, QsCard } };
</script>

<style scoped>
#FestivalShowsCashless {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 200px 350px 45px 250px 400px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 20px;
}
</style>
